import { useCallback, useContext, useEffect, useMemo, useState } from "react"
import { Button, Grid, Tab, Tabs, Tooltip } from "@mui/material"
import { LookupTablesContext } from "../../../context/LookupTablesContext"
import { DateTime } from "luxon"
import axios from "axios"
import { toast } from "react-toastify"
import { formatNumberToMoney, roundTwoDecimals } from "../../Utils"
import { Add, Cancel, CurrencyExchange, Remove, Save } from "@mui/icons-material"
import CashflowForm from "./CashflowForm"
import { ViewDefaultBilling } from "../_billings/DefaultBillings"
import PreLoader from "../../PreLoader"
import { useMsal } from "@azure/msal-react"
import CashFlowTable from "./CashFlowTable"
import { toastError, toastSuccess } from "../../assets/customToasts"
import { ViewBilling, ViewBundle } from "./types"

type Props = {
  billingIds?: number[]
  bundleId?: number
  customerIds?: number[]
  next?: () => void
  onClose: () => void
  referenceYear?: number | null
  refreshTable?: () => Promise<void>
}

type AutocompleteType = {
  id: number;
  label: string;
} | null

type NumericLabelValues = {
  value: number
  label: string
}

export type Billing = {
  id?: number
  affiliation: AutocompleteType
  defaultBilling: AutocompleteType
  issueDate: DateTime | null
  service: AutocompleteType
  beneficiaryType: number | null
  beneficiary: AutocompleteType
  payerType: number | null
  payer: AutocompleteType
  payee: number | null
  referenceYear: number | null
  currency: number
  totalBilling: NumericLabelValues
}

export type Discount = {
  type: 'percentage' | 'setValue'
  percentage: NumericLabelValues
  setValue: NumericLabelValues
}

export type CashFlowOptions = {
  mode: 'numberOfBills' | 'setValue'
  numberOfBills: NumericLabelValues
  setValue: NumericLabelValues
  dateOfFirstCashflow: DateTime | null
  remainder: 'first-bill' | 'last-bill' | 'equal'
}

export type CashFlowInstallment = {
  id?: number
  fk_id_billing?: number
  installment_number: number
  due_date: DateTime | null
  paid_date: DateTime | null
  grossValue: NumericLabelValues
  dueValue: NumericLabelValues
  paid_value: number
  fk_id_payment_method: number | null
  comment: string | null
  isModified?: boolean
}

export type BillingsWithCashFlow = {
  billing: Billing
  discount: Discount
  cashFlowOptions: CashFlowOptions
  cashFlowInstallments: CashFlowInstallment[]
}

export default function CashFlow({ billingIds, bundleId, customerIds, next, onClose, referenceYear, refreshTable }: Props) {
  console.log(billingIds, bundleId, customerIds)
  // Hooks
  const { lookupTables } = useContext(LookupTablesContext)
  const { accounts } = useMsal()
  // General
  const [loading, setLoading] = useState<boolean>(false)
  const [disableSaveButton, setDisableSaveButton] = useState<boolean>(false)
  // Data
  const [tabIndex, setTabIndex] = useState<number>(0)
  const [forms, setForms] = useState<BillingsWithCashFlow[]>([
    {
      billing: {
        affiliation: null,
        defaultBilling: null,
        issueDate: DateTime.utc(),
        service: null,
        beneficiaryType: null,
        beneficiary: null,
        payerType: null,
        payer: null,
        payee: null,
        referenceYear: referenceYear || DateTime.utc().get('year'),
        currency: 5,
        totalBilling: {
          value: 0, // Computed value as number, for the calculations
          label: '' // User input is a string so the user can type anything, in case the user type something invalid, we set the error on the component
        }
      },
      discount: {
        type: 'percentage',
        percentage: {
          value: 0, // Computed value as number, for the calculations
          label: '' // User input is a string so the user can type anything, in case the user type something invalid, we set the error on the component
        },
        setValue: {
          value: 0, // Computed value as number, for the calculations
          label: '' // User input is a string so the user can type anything, in case the user type something invalid, we set the error on the component
        }
      },
      cashFlowOptions: {
        mode: 'numberOfBills',
        numberOfBills: {
          value: 0, // Computed value as number, for the calculations
          label: '' // User input is a string so the user can type anything, in case the user type something invalid, we set the error on the component
        },
        setValue: {
          value: 0, // Computed value as number, for the calculations
          label: '' // User input is a string so the user can type anything, in case the user type something invalid, we set the error on the component
        },
        dateOfFirstCashflow: DateTime.utc(),
        remainder: 'first-bill'
      },
      cashFlowInstallments: []
    }
  ])
  const [deletedIds, setDeletedIds] = useState<number[]>([])

  // useMemos
  const selectedForm = useMemo(() => forms[tabIndex], [forms, tabIndex])

  const currencySign = useMemo(() => lookupTables.currency.find((_) => _.id === forms[tabIndex].billing.currency)?.sign || 'R$', [lookupTables, forms, tabIndex])



  const isFormSubmittable = useMemo(() => {
    // If there's something wrong in the forms
    if (forms.find((_form) => !_form.billing.service || (!_form.billing.beneficiary && !customerIds?.length) || (!_form.billing.payer && !customerIds?.length) || !_form.billing.payee || isNaN(_form.billing.totalBilling.value))) return false
    // If no cash flow has been created
    if (forms.find((_form) => !_form.cashFlowInstallments.length)) return false
    // If any cash flow has missing information
    if (forms.find((_form) => _form.cashFlowInstallments.find((_installment) => !_installment.due_date || isNaN(_installment.grossValue.value) || isNaN(_installment.dueValue.value) || !_installment.fk_id_payment_method))) return false

    return true
  }, [forms, customerIds])

  // In this method we send the whole object to the backend, to keep a better control of the bundles
  const postBillingAndCashFlow = useCallback(async (customerId?: number) => {
    const billingsWithCashFlow = forms.map((_) => ({
      fk_id_affiliation: _.billing.affiliation!.id,
      fk_id_service: _.billing.service!.id,
      fk_id_currency: _.billing.currency,
      fk_id_payee: _.billing.payee,
      fk_id_default_billing: _.billing.defaultBilling?.id || null,
      fk_id_customer_payer: _.billing.payer?.id || customerId,
      fk_id_customer_beneficiary: _.billing.beneficiary?.id || customerId,
      fk_id_bundle: bundleId || null,
      fk_id_service_provided_type: null,
      reference_year: _.billing.referenceYear,
      cashFlow: _.cashFlowInstallments.map((_installment) => ({
        installment_number: _installment.installment_number,
        due_date: _installment.due_date!.toISODate(),
        paid_date: _installment.paid_date?.toISODate() || null,
        gross_value: _installment.grossValue.value,
        due_value: _installment.dueValue.value,
        paid_value: _installment.paid_value,
        fk_id_payment_method: _installment.fk_id_payment_method,
        comment: _installment.comment,
        updated_by: accounts[0]?.username || 'Cypress testing'
      }))
    }))

    await axios({
      method: 'POST',
      url: bundleId ? `${process.env.REACT_APP_SIS_BACKEND_URL}/assign-bundles` : `${process.env.REACT_APP_SIS_BACKEND_URL}/billings`,
      headers: {
        authorization: `Bearer ${process.env.REACT_APP_SIS_BACKEND_TOKEN}`
      },
      data: {
        billingsWithCashFlow
      }
    })
  }, [forms, accounts, bundleId])

  // Http requests
  const onSave = async () => {
    setDisableSaveButton(true)

    const _toast = toast('Saving cash flow', { toastId: 'toast-cash-flow-on-save', isLoading: true })

    try {
      // PUT
      if (billingIds?.length) {
        const cashFlow = forms
          .flatMap((_form) => _form.cashFlowInstallments)
          .map((_installment) => ({
            id: _installment.id,
            fk_id_billing: _installment.fk_id_billing,
            installment_number: _installment.installment_number,
            due_date: _installment.due_date!.toISO({ includeOffset: false })!,
            paid_date: _installment.paid_date?.toISO({ includeOffset: false })! || null,
            gross_value: _installment.grossValue.value,
            due_value: _installment.dueValue.value,
            paid_value: _installment.paid_value,
            fk_id_payment_method: _installment.fk_id_payment_method,
            comment: _installment.comment || null,
            updated_by: accounts[0]?.username || 'Cypress testing'
          }))

        await axios({
          method: 'PUT',
          url: `${process.env.REACT_APP_SIS_BACKEND_URL}/billing-cashflow/0`,
          headers: {
            Authorization: `Bearer ${process.env.REACT_APP_SIS_BACKEND_TOKEN}`
          },
          data: { cashFlow }
        })

        // Deleted installments
        const allPromises = deletedIds.map(async (deletedId) => {
          await axios({
            method: 'DELETE',
            url: `${process.env.REACT_APP_SIS_BACKEND_URL}/billing-cashflow/${deletedId}`,
            headers: {
              Authorization: `Bearer ${process.env.REACT_APP_SIS_BACKEND_TOKEN}`
            },
          })
        })


        await Promise.all(allPromises)

        toastSuccess(_toast, 'Cash flow saved.')
      }
      // POST
      else {
        // If there are preset customers
        if (customerIds) {
          const allPromises = customerIds.map(async (_id) => await postBillingAndCashFlow(_id))

          await Promise.all(allPromises)
        }
        // If there are no preset customers
        else {
          await postBillingAndCashFlow()
        }
      }

      toastSuccess(_toast, 'Billings and cash flow saved.')

      onClose()

      if (next) next()
      if (refreshTable) refreshTable()
    } catch (err) {
      console.log(err)
      toastError(_toast, 'Could not save cash flow.')
    }

    setDisableSaveButton(false)
  }

  // When editing a billing
  const fetchBillings = useCallback(async () => {
    setLoading(true)

    try {
      const { data } = await axios({
        method: 'GET',
        url: `${process.env.REACT_APP_SIS_BACKEND_URL}/billing-cashflow?${billingIds?.map((_) => `ids[]=${_}`).join('&')}`,
        headers: {
          Authorization: `Bearer ${process.env.REACT_APP_SIS_BACKEND_TOKEN}`
        }
      })

      console.log(data)

      const _forms: BillingsWithCashFlow[] = data.map((_billing: ViewBilling) => {
        const _percentage = (1 - (_billing.cashflow.reduce((prev, curr) => prev += curr.due_value, 0) / _billing.cashflow.reduce((prev, curr) => prev += curr.gross_value, 0))) * 100
        const _setValue = parseFloat((_billing.cashflow.reduce((prev, curr) => prev += curr.gross_value, 0) - _billing.cashflow.reduce((prev, curr) => prev += curr.due_value, 0)).toFixed(2))

        const _installments = _billing.cashflow.map((_) => ({
          id: _.id,
          fk_id_billing: _.fk_id_billing,
          installment_number: _.installment_number,
          due_date: DateTime.fromISO(_.due_date, { zone: 'America/New_York' }),
          paid_date: _.paid_date ? DateTime.fromISO(_.paid_date, { zone: 'America/New_York' }) : null,
          grossValue: {
            value: _.gross_value,
            label: formatNumberToMoney(_.gross_value),
          },
          dueValue: {
            value: _.due_value,
            label: formatNumberToMoney(_.due_value),
          },
          paid_value: _.paid_value,
          fk_id_payment_method: _.fk_id_payment_method,
          comment: _.comment
        }))

        return {
          billing: {
            id: _billing.id,
            affiliation: { id: _billing.beneficiary_fk_id_affiliation, label: _billing.beneficiary_affiliation_name },
            defaultBilling: _billing.fk_id_default_billing ? { id: _billing.fk_id_default_billing, label: _billing.default_billing_name } : null,
            issueDate: DateTime.fromISO(_billing.issue_date || '', { zone: 'America/New_York' }),
            service: { id: _billing.fk_id_service, label: _billing.service_name },
            beneficiaryType: _billing.beneficiary_customer_type,
            beneficiary: { id: _billing.fk_id_customer_beneficiary, label: _billing.beneficiary_customer_name },
            payerType: _billing.payer_customer_type,
            payer: { id: _billing.fk_id_customer_payer, label: _billing.payer_customer_name },
            payee: _billing.fk_id_payee,
            currency: 5,
            referenceYear: _billing.reference_year,
            totalBilling: {
              value: _billing.default_value || 0,
              label: _billing.default_value ? formatNumberToMoney(_billing.default_value) : '',
            }
          },
          discount: {
            type: 'percentage',
            percentage: {
              value: Math.round(_percentage),
              label: Math.round(_percentage).toString(),
            },
            setValue: {
              value: _setValue,
              label: String(_setValue),
            }
          },
          cashFlowOptions: {
            mode: 'numberOfBills',
            numberOfBills: {
              value: 0,
              label: '0',
            },
            setValue: {
              value: 0,
              label: '',
            },
            dateOfFirstCashflow: DateTime.utc(),
            remainder: 'first-bill'
          },
          cashFlowInstallments: _installments
        }
      })

      setForms(_forms)
    } catch (err) {
      console.log(err)
      toast.error('Could not load billings.')
    }

    setLoading(false)
  }, [billingIds, setLoading, setForms])

  // When creating a bundle
  const setDefaultBillings = useCallback(async () => {
    setLoading(true)

    try {
      const { data }: { data: ViewBundle } = await axios({
        method: 'GET',
        url: `${process.env.REACT_APP_SIS_BACKEND_URL}/bundles/${bundleId}`,
        headers: {
          Authorization: `Bearer ${process.env.REACT_APP_SIS_BACKEND_TOKEN}`
        }
      })

      console.log(data)

      setForms((_forms) => {
        return data.default_billings.map((_) => ({
          billing: {
            affiliation: { id: _.fk_id_affiliation, label: _.affiliation_name },
            defaultBilling: { id: _.fk_id_default_billing, label: _.default_billing_name },
            issueDate: DateTime.utc(),
            service: { id: _.fk_id_service, label: _.service_name },
            beneficiaryType: 2,
            beneficiary: null,
            payerType: _.fk_id_customer_type,
            payer: null,
            payee: _.fk_id_payee,
            referenceYear: data.reference_year || DateTime.utc().get('year'),
            currency: _.fk_id_currency,
            totalBilling: {
              value: _.default_value || 0,
              label: _.default_value ? formatNumberToMoney(_.default_value) : '',
            }
          },
          discount: {
            type: 'percentage',
            percentage: {
              value: 0,
              label: '',
            },
            setValue: {
              value: 0,
              label: '',
            }
          },
          cashFlowOptions: {
            mode: 'numberOfBills',
            numberOfBills: {
              value: _.max_installments,
              label: _.max_installments.toString(),
            },
            setValue: {
              value: 0,
              label: '',
            },
            dateOfFirstCashflow: DateTime.utc(),
            remainder: 'first-bill'
          },
          cashFlowInstallments: []
        }))
      })

      setLoading(false)
    } catch (err) {
      console.log(err)
      toast.error('Could not load default billings.')
    }
  }, [bundleId])

  useEffect(() => {
    if (billingIds) fetchBillings()
    else if (bundleId) setDefaultBillings()
  }, [billingIds, bundleId, fetchBillings, setDefaultBillings])

  // Cash flow
  const setCashFlow = useCallback((netValue: number, selectedDefaultBilling: ViewDefaultBilling | null) => {
    const _cashFlowInstallments: CashFlowInstallment[] = []

    // Calculating net value per bill
    const dueValuePerBill = selectedForm.cashFlowOptions.mode === 'numberOfBills' ? roundTwoDecimals(netValue / selectedForm.cashFlowOptions.numberOfBills.value) : selectedForm.cashFlowOptions.setValue.value!
    // Number of installments
    const numberOfInstallments = selectedForm.cashFlowOptions.mode === 'numberOfBills' ? selectedForm.cashFlowOptions.numberOfBills.value : Math.floor(netValue / selectedForm.cashFlowOptions.setValue.value)
    // Gross value per bill
    const grossValue = roundTwoDecimals(selectedForm.billing.totalBilling.value / numberOfInstallments)
    // Remainder
    var remainder = netValue

    for (var counter = 0; counter < numberOfInstallments; counter++) {
      remainder -= dueValuePerBill

      _cashFlowInstallments.push({
        installment_number: counter + 1,
        comment: null,
        due_date: selectedForm.cashFlowOptions.dateOfFirstCashflow!.plus({ month: counter }),
        dueValue: {
          value: dueValuePerBill,
          label: formatNumberToMoney(dueValuePerBill),
        },
        fk_id_payment_method: selectedDefaultBilling?.fk_id_payment_method || null,
        grossValue: {
          value: grossValue,
          label: formatNumberToMoney(grossValue),
        },
        paid_date: null,
        paid_value: 0
      })
    }

    // Remainder
    if (remainder > 0) {
      // Rounding the remainder
      remainder = roundTwoDecimals(remainder)

      // Check remainder option
      if (selectedForm.cashFlowOptions.remainder === 'first-bill') {
        _cashFlowInstallments[0].dueValue.value += remainder
        _cashFlowInstallments[0].dueValue.label = formatNumberToMoney(_cashFlowInstallments[0].dueValue.value)
      }

      if (selectedForm.cashFlowOptions.remainder === 'last-bill') {
        _cashFlowInstallments[_cashFlowInstallments.length - 1].dueValue.value += remainder
        _cashFlowInstallments[_cashFlowInstallments.length - 1].dueValue.label = formatNumberToMoney(_cashFlowInstallments[_cashFlowInstallments.length - 1].dueValue.value)
      }

      if (selectedForm.cashFlowOptions.remainder === 'equal') {
        const remainderShare = roundTwoDecimals(remainder / numberOfInstallments) || 0.01

        for (var _counter = 0; _counter < numberOfInstallments; _counter++) {
          // console.log(remainder)

          remainder -= remainderShare

          if (remainder <= 0) break
          // Final installment
          if (_counter === numberOfInstallments - 1) {
            _cashFlowInstallments[_counter].dueValue.value += remainder
            _cashFlowInstallments[_counter].dueValue.label = formatNumberToMoney(_cashFlowInstallments[_counter].dueValue.value)
          }

          _cashFlowInstallments[_counter].dueValue.value += remainderShare
          _cashFlowInstallments[_counter].dueValue.label = formatNumberToMoney(_cashFlowInstallments[_counter].dueValue.value)
        }
      }
    }

    setForms((prev) => {
      const _prev = [...prev]

      _prev[tabIndex].cashFlowInstallments = _cashFlowInstallments

      return _prev
    })
  }, [selectedForm, setForms, tabIndex])

  const clearCashFlow = useCallback(() => {
    setForms((prev) => {
      const _prev = [...prev]

      _prev[tabIndex].cashFlowInstallments = []

      return _prev
    })
  }, [setForms, tabIndex])

  // Upper buttons
  const resetDueValues = useCallback(() => {
    setForms((prev) => {
      const _prev = [...prev]
      const _discount = { ...prev[tabIndex].discount }
      const _installments = [...prev[tabIndex].cashFlowInstallments]


      _prev[tabIndex].cashFlowInstallments = _installments.map((_) => {
        const _dueValue = _discount.type === 'setValue' ? roundTwoDecimals(_.grossValue.value - (_discount.setValue.value / _installments.length)) : _.grossValue.value * (1 - _discount.percentage.value / 100)

        return {
          ..._,
          dueValue: {
            value: _dueValue,
            label: formatNumberToMoney(_dueValue)
          }
        }
      })

      return _prev
    })
  }, [setForms, tabIndex])

  const addCashFlow = useCallback(() => {
    setForms((prev) => {
      const _prev = [...prev]
      const _installments = [...prev[tabIndex].cashFlowInstallments]

      const _newInstallment: CashFlowInstallment = {
        installment_number: _installments.length + 1,
        comment: null,
        due_date: null,
        dueValue: {
          value: 0,
          label: '',
        },
        fk_id_payment_method: 2,
        grossValue: {
          value: 0,
          label: '',
        },
        paid_date: null,
        paid_value: 0,
      }

      if (billingIds) _newInstallment.fk_id_billing = billingIds[tabIndex]

      _prev[tabIndex].cashFlowInstallments = _installments.concat(_newInstallment)

      return _prev
    })
  }, [billingIds, setForms, tabIndex])

  // Billings operation
  const newBilling = useCallback(() => {
    // Create a new billing in the arrays
    setForms((prev) => prev.concat({
      billing: {
        affiliation: null,
        defaultBilling: null,
        issueDate: DateTime.utc(),
        service: null,
        beneficiaryType: null,
        beneficiary: null,
        payerType: null,
        payer: null,
        payee: null,
        referenceYear: referenceYear || DateTime.utc().get('year'),
        currency: 5,
        totalBilling: {
          value: 0,
          label: ''
        }
      },
      discount: {
        type: 'percentage',
        percentage: {
          value: 0,
          label: ''
        },
        setValue: {
          value: 0,
          label: ''
        }
      },
      cashFlowOptions: {
        mode: 'numberOfBills',
        numberOfBills: {
          value: 0,
          label: ''
        },
        setValue: {
          value: 0,
          label: ''
        },
        dateOfFirstCashflow: DateTime.utc(),
        remainder: 'first-bill'
      },
      cashFlowInstallments: []
    }))

    // In this set state, forms is not updated, meaning it's with the old value
    // This is why setting forms.length works
    setTabIndex(forms.length)
  }, [forms, referenceYear])

  const removeBilling = useCallback(() => {
    setTabIndex((prev) => prev + 1 >= forms.length ? prev - 1 : prev)

    setForms((prev) => prev.filter((_, index) => index !== tabIndex))
  }, [forms, tabIndex, setForms, setTabIndex])

  // Update methods
  const updateBilling = useCallback((newBilling: Billing) => {
    setForms((prev) => {
      const _prev = [...prev]

      _prev[tabIndex].billing = newBilling

      return _prev
    })
  }, [setForms, tabIndex])

  const updateDiscount = useCallback((newDiscount: Discount) => {
    setForms((prev) => {
      const _prev = [...prev]

      _prev[tabIndex].discount = newDiscount

      return _prev
    })
  }, [setForms, tabIndex])

  const updateCashFlowOptions = useCallback((newCashFlowOptions: CashFlowOptions) => {
    setForms((prev) => {
      const _prev = [...prev]

      _prev[tabIndex].cashFlowOptions = newCashFlowOptions

      return _prev
    })
  }, [setForms, tabIndex])

  const updateCashFlowInstallments = useCallback((index: number, newInstallment: Partial<CashFlowInstallment>) => {
    setForms((prev) => {
      const _prev = [...prev]

      _prev[tabIndex].cashFlowInstallments[index] = { ..._prev[tabIndex].cashFlowInstallments[index], ...newInstallment }

      return _prev
    })
  }, [setForms, tabIndex])

  const deleteCashFlowInstallments = useCallback((index: number, id: number | undefined) => {
    if (id) setDeletedIds((ids) => ids.concat(id))

    setForms((prev) => {
      const _prev = [...prev]

      _prev[tabIndex].cashFlowInstallments = _prev[tabIndex].cashFlowInstallments
        .filter((_installment, _index) => _index !== index)
        .map((_installment, index) => ({
          ..._installment,
          installment_number: index + 1
        }))

      return _prev
    })
  }, [setForms, tabIndex])

  return loading ?
    <PreLoader />
    :
    <>
      {/* Form */}
      <Grid item xs={4}>
        <CashflowForm
          currencySign={currencySign}
          customerIds={customerIds || []}
          hasPresetBundle={Boolean(bundleId)}
          hasPresetCustomers={Boolean(customerIds)}
          selectedForm={selectedForm}
          clearCashFlow={clearCashFlow}
          setCashFlow={setCashFlow}
          updateBilling={updateBilling}
          updateCashFlowOptions={updateCashFlowOptions}
          updateDiscount={updateDiscount}
        />
      </Grid>
      {/* Table */}
      <Grid item xs={8}>
        <Grid container spacing={1.5}>
          {/* Tabs */}
          <Grid item xs={10}>
            <Tabs id='cash-flow-form-tabs' value={tabIndex} onChange={(e, newValue) => setTabIndex(Number(newValue))} variant='scrollable' scrollButtons='auto'>
              {
                forms.map((_, index) => <Tab
                  key={index}
                  id={`cash-flow-form-tab-${index}`}
                  value={index}
                  label={_.billing.defaultBilling?.label || _.billing.service?.label || `Billing ${index + 1}`}
                  sx={{ width: 200 }}
                />)
              }
            </Tabs>
          </Grid>
          <Grid item xs={1}>
            <Tooltip title='Create a new billing'>
              <Button
                id='button-new-billing'
                onClick={newBilling}
                color='secondary'
                disabled={Boolean(billingIds?.length)}
              >
                <Add color='primary' />
              </Button>
            </Tooltip>
          </Grid>
          <Grid item xs={1}>
            <Tooltip title={`Remove a billing: ${selectedForm.billing.defaultBilling?.label || selectedForm.billing.service?.label || 'Billing ' + (tabIndex + 1)}`}>
              <Button
                id='button-remove-billing'
                onClick={removeBilling}
                color='warning'
                disabled={forms.length <= 1 || Boolean(billingIds?.length)}
              >
                <Remove color='primary' />
              </Button>
            </Tooltip>
          </Grid>
          {/* Spacer */}
          <Grid item xs={7.5} />
          {/* Buttons */}
          <Grid item xs={3}>
            <Button
              id='button-reset-due-values'
              startIcon={<CurrencyExchange />}
              color='info'
              onClick={resetDueValues}
            >
              Reset due values
            </Button>
          </Grid>
          <Grid item xs={1.5}>
            <Button
              id='button-add-cash-flow'
              startIcon={<Add />}
              onClick={addCashFlow}
            >
              Add
            </Button>
          </Grid>
          <Grid item xs={12}>
            <CashFlowTable
              currencySign={currencySign}
              installments={selectedForm.cashFlowInstallments}
              updateCashFlowInstallments={updateCashFlowInstallments}
              deleteCashFlowInstallments={deleteCashFlowInstallments}
            />
          </Grid>
          {/* Spacer */}
          <Grid item xs={6} />
          {/* Buttons */}
          <Grid item xs={3}>
            <Button
              id='button-cancel'
              color='error'
              startIcon={<Cancel />}
              onClick={onClose}
            >
              Cancel
            </Button>
          </Grid>
          <Grid item xs={3}>
            <Button
              id='button-save'
              startIcon={<Save />}
              onClick={onSave}
              disabled={disableSaveButton || !isFormSubmittable}
            >
              Save
            </Button>
          </Grid>
          {/* Spacer */}
          <Grid item xs={8} />
        </Grid>
      </Grid >
    </>
}
