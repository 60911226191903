import { Button, Grid, IconButton, InputAdornment, Menu, MenuItem, TextField, Tooltip, Typography } from '@mui/material'
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers'
import { Fragment, useContext, useState } from 'react'
import { CashFlowInstallment } from './CashFlow'
import { AdapterLuxon } from '@mui/x-date-pickers/AdapterLuxon'
import { allowMoneyFormat, formatNumberToMoney } from '../../Utils'
import { LookupTablesContext } from '../../../context/LookupTablesContext'
import { Check, Delete } from '@mui/icons-material'
import { DateTime } from 'luxon'

type Props = {
  installments: CashFlowInstallment[]
  currencySign: string
  updateCashFlowInstallments: (index: number, newInstallment: Partial<CashFlowInstallment>) => void
  deleteCashFlowInstallments: (index: number, id: number | undefined) => void
}

type MenuType = {
  index: number | null
  button: HTMLElement | null
}

export default function CashFlowTable({ installments, currencySign, updateCashFlowInstallments, deleteCashFlowInstallments }: Props) {
  // Hooks
  const { lookupTables } = useContext(LookupTablesContext)
  // General
  const [menu, setMenu] = useState<MenuType>({
    index: null,
    button: null
  })

  // Convenience
  const handleNavigate = (code: string, elementId: string, row: number) => {
    switch (code) {
      case 'ArrowUp':
        return document.getElementById(`${elementId}${row - 1}`)?.focus()
      case 'ArrowDown':
        return document.getElementById(`${elementId}${row + 1}`)?.focus()
      // Ignore any button other than the arrows
      default:
        return
    }
  }

  return <Grid container spacing={.75}>
    {
      installments.map((_installment, index) => <Fragment key={index}>
        <Grid item xs={1}>
          <TextField
            id={`cash-flow-id-${index}`}
            label='ID'
            value={_installment.id || ''}
            onChange={console.log}
            disabled
          />
        </Grid>
        <Grid item xs={1.75}>
          <LocalizationProvider dateAdapter={AdapterLuxon}>
            <DatePicker
              slotProps={{
                textField: {
                  id: `cash-flow-due-date-${index}`
                }
              }}
              format="dd-MMM-yy"
              label="Due date"
              value={_installment.due_date}
              onChange={(newValue) => updateCashFlowInstallments(index, { due_date: newValue })}
            />
          </LocalizationProvider>
        </Grid>
        <Grid item xs={1.75}>
          <TextField
            id={`cash-flow-gross-value-${index}`}
            label='Gross value'
            value={_installment.grossValue.label}
            onChange={(e) => updateCashFlowInstallments(index, { grossValue: { value: allowMoneyFormat(e.target.value), label: e.target.value } })}
            error={isNaN(_installment.grossValue.value)}
            onKeyDown={(e) => handleNavigate(e.code, 'cash-flow-gross-value-', index)}
            onBlur={() => {
              const _ = { ..._installment }

              _.grossValue.label = formatNumberToMoney(_.grossValue.value)

              updateCashFlowInstallments(index, _)
            }}
            onFocus={() => {
              const _ = { ..._installment }

              _.grossValue.label = _.grossValue.value.toFixed(2)

              updateCashFlowInstallments(index, _)
            }}
            InputProps={
              { startAdornment: <InputAdornment position='start'>{currencySign}</InputAdornment> }
            }
          />
        </Grid>
        <Grid item xs={1.75}>
          <TextField
            id={`cash-flow-due-value-${index}`}
            label='Due value'
            value={_installment.dueValue.label}
            onChange={(e) => updateCashFlowInstallments(index, { dueValue: { value: allowMoneyFormat(e.target.value), label: e.target.value } })}
            error={isNaN(_installment.dueValue.value)}
            onKeyDown={(e) => handleNavigate(e.code, 'cash-flow-due-value-', index)}
            onBlur={() => {
              const _ = { ..._installment }

              _.dueValue.label = formatNumberToMoney(_.dueValue.value)

              updateCashFlowInstallments(index, _)
            }}
            onFocus={() => {
              const _ = { ..._installment }

              _.dueValue.label = _.dueValue.value.toFixed(2)

              updateCashFlowInstallments(index, _)

            }}
            InputProps={
              { startAdornment: <InputAdornment position='start'>{currencySign}</InputAdornment> }
            }
          />
        </Grid>
        <Grid item xs={1.75}>
          <TextField
            id={`cash-flow-payment-method-${index}`}
            label='Payment method'
            value={_installment.fk_id_payment_method}
            onChange={(e) => updateCashFlowInstallments(index, { fk_id_payment_method: Number(e.target.value) })}
            onKeyDown={(e) => handleNavigate(e.code, 'cash-flow-payment-method-', index)}
            select
          >
            {
              lookupTables.paymentMethod.map((_, index) => <MenuItem key={index} id={`payment-method-option-${_.id}`} value={_.id}>{_.option}</MenuItem>)
            }
          </TextField>
        </Grid>
        <Grid item flexGrow={1}>
          <TextField
            id={`cash-flow-comments-${index}`}
            label='Comments'
            value={_installment.comment || ''}
            onChange={(e) => updateCashFlowInstallments(index, { comment: e.target.value })}
            onKeyDown={(e) => handleNavigate(e.code, 'cash-flow-comments-', index)}
          />
        </Grid>
        {
          _installment.id ?
            <>
              <Grid item xs={.6} sx={{ display: 'flex', alignItems: 'center' }}>
                <Tooltip title='Mark as paid'>
                  <IconButton
                    id={`button-mark-as-paid-${index}`}
                    onClick={(e) => {
                      updateCashFlowInstallments(index, { paid_date: _installment.paid_date || DateTime.now(), paid_value: _installment.dueValue.value })
                      setMenu({ index, button: e.currentTarget })
                    }}
                    sx={{ backgroundColor: _installment.paid_date ? 'var(--color-green)' : '' }}
                  >
                    <Check />
                  </IconButton>
                </Tooltip>
              </Grid>
              {
                menu.index === index ?
                  <Menu
                    id='menu-mark-as-paid'
                    open={Boolean(menu.button)}
                    anchorEl={menu.button}
                    onClose={() => setMenu({ index: null, button: null })}
                    anchorOrigin={{
                      vertical: 'bottom',
                      horizontal: 'right',
                    }}
                    transformOrigin={{
                      vertical: 'top',
                      horizontal: 'right',
                    }}
                    slotProps={{ paper: { sx: { padding: 1, width: 250 } } }}
                  >
                    <Grid container>
                      <Grid item xs={12}>
                        <LocalizationProvider dateAdapter={AdapterLuxon}>
                          <DatePicker
                            slotProps={{
                              textField: {
                                id: `cash-flow-paid-date-${index}`
                              }
                            }}
                            format="dd-MMM-yy"
                            label="Paid date"
                            value={_installment.paid_date}
                            onChange={(newValue) => updateCashFlowInstallments(index, { paid_date: newValue })}
                          />
                        </LocalizationProvider>
                      </Grid>
                      <Grid item xs={12}>
                        <Typography textAlign={'center'}>
                          Or
                        </Typography>
                      </Grid>
                      <Grid item xs={12}>
                        <Button
                          id='button-remove-mark-as-paid'
                          onClick={() => {
                            updateCashFlowInstallments(index, { paid_date: null, paid_value: 0 })
                          }}
                        >
                          Remove Mark as paid
                        </Button>
                      </Grid>
                    </Grid>
                  </Menu>
                  :
                  null
              }
            </>
            :
            null
        }
        <Grid item xs={.6} sx={{ display: 'flex', alignItems: 'center' }}>
          <Tooltip title='Delete installment'>
            <IconButton
              id={`button-delete-cash-flow-${index}`}
              color='error'
              onClick={() => deleteCashFlowInstallments(index, _installment.id)}
            >
              <Delete />
            </IconButton>
          </Tooltip>
        </Grid>
      </Fragment>
      )
    }
  </Grid >
}
